import Step1 from './components/Step1'
import Step2 from './components/Step2'
import Step3 from './components/Step3'
import { useAppBridge } from '@shopify/app-bridge-react'
import { authenticatedFetch } from '@shopify/app-bridge-utils'
import useCustomHistory from '../../hooks/useCustomHistory'

import { Tabs, Button, Page, Icon, Loading } from '@shopify/polaris'
import { ChevronLeftMinor, ChevronRightMinor } from '@shopify/polaris-icons'
import { useState } from 'react'
import moment from 'moment'
import UpsellModal from '../../components/Upsell/UpsellModal'
import request from '../../lib/request'
import { useChangePlan } from '../Pricing/hooks'

export default function BulkTestForm({ setShowToast, setMessage }) {
  const app = useAppBridge()
  const authFetch = authenticatedFetch(app)

  const formInitalData = {
    start_date: moment(),
    finish_date: moment().add(1, 'days'),
    variant_every_type: 'minutes',
    variant_every: 60,
    bulk_change: {
      'column': 'price',
      'percentage': 10,
      'operation': 'increase',
      'rounding': 'round',
    },
  }
  const [formData, setFormData] = useState(formInitalData)
  const [touchedName, setTouchedName] = useState(false)
  const [validationErrors, setValidationErrors] = useState({})
  const [step, setStep] = useState(0)
  const [submited, setSubmited] = useState(false)
  const [shop, setShop] = useState({})
  const [showUpsell, setShowUpsell] = useState(false)
  const [changePlan] = useChangePlan()
  const [impressionLimitReached, setImpressionLimitReached] = useState(false)

  const history = useCustomHistory()

  const tabs = [
    {
      id: 'step1',
      content: '1. Test Details',
    },
    {
      id: 'step2',
      content: '2. Variants',
    },
    {
      id: 'step3',
      content: '3. Period',
    }]

  function nextStep() {
    setStep(step + 1)
  }

  function previousStep() {
    setStep(step - 1)
  }

  function showStep(selected) {
    switch (tabs[selected].id) {
      case 'step1':
        return <Step1 formData={formData} setFormData={setFormData} validationErrors={validationErrors} setValidationErrors={setValidationErrors} touchedName={touchedName} setTouchedName={setTouchedName}/>
      case 'step2':
        return <Step2 formData={formData} setFormData={setFormData} />
      case 'step3':
        return <Step3 formData={formData} setFormData={setFormData} validationErrors={validationErrors} setValidationErrors={setValidationErrors} shop={shop} />
      default:
        return ''
    }
  }

  function renderFooterButtons() {

    function footerButtons({ previousLabel, previousClickHandler, nextLabel, nextClickHandler }) {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button size="large" onClick={previousClickHandler} > <div style={{ display: 'flex' }}> <Icon source={ChevronLeftMinor} />{previousLabel}</div></Button>
          <Button primary disabled={submited} size="large" onClick={nextClickHandler}><div style={{ display: 'flex' }}>{nextLabel}<Icon source={ChevronRightMinor} /></div></Button>
        </div>
      )
    }

    switch (tabs[step].id) {
      case 'step1':
        return footerButtons({
          previousLabel: 'Cancel test & return',
          previousClickHandler: () => history.push('/'),
          nextLabel: 'Next Step',
          nextClickHandler: handleOnClickStep1,
        })
      case 'step2':
        return footerButtons({
          previousLabel: 'Previous Step',
          previousClickHandler: previousStep,
          nextLabel: 'Next Step',
          nextClickHandler: handleOnClickStep2,
        })
      case 'step3':
        return footerButtons({
          previousLabel: 'Previous Step',
          previousClickHandler: previousStep,
          nextLabel: 'Create Bulk Test',
          nextClickHandler: handleOnClickStep3,
        })
      default:
        return ''
    }
  }

  function handleOnClickStep1() {

    if (!formData.name || formData.name.trim() === '') {
      setValidationErrors({ ...validationErrors, name: true })
      setTouchedName(true)
      return
    }

    if (!formData.bulk_change.products || formData.bulk_change.products.length < 1) {
      setValidationErrors({ ...validationErrors, products: true })
      return
    }

    nextStep()
  }

  function checkImpressionsLimit(shop) {
    if (shop?.pricingPlanData?.impressionLimit < 0) {
      setImpressionLimitReached(false)
      return
    }
    if (shop?.currentMonthImpressions < shop?.pricingPlanData?.impressionLimit) {
      setImpressionLimitReached(false)
      return
    }
    setImpressionLimitReached(true)
  }

  async function handleOnClickStep2() {
    const response = await authFetch('/api/default/shop', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    })

    const shopData = await response.json()
    setShop(shopData)
    checkImpressionsLimit(shopData)
    nextStep()
  }

  const checkFreeLimitReached = async () => {
    const id = '100' //refactor: remove id
    return request('test/' + id + '/reached_free_plan_limitation', 'get', undefined, undefined, app)
  }

  const createBulkTest = async (status = 'running') => {
    return authFetch('/api/bulk_test', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...formData,
        status
      }),
    })
  }

  const createWithRedirect = (redirectTo, planName) => {

    if (redirectTo === 'upgrade') {
      changePlan(planName)
    } else {
      history.push('/pricing')
    }
    createBulkTest('paused')
    setShowToast(true)
    setMessage('Saved')
  }

  async function handleOnClickStep3() {

    if (validationErrors.datetime) {
      return
    }

    if (!formData.priceWarningAgreement) {
      setValidationErrors({ ...validationErrors, priceWarningAgreement: true })
      return
    }

    setSubmited(true)
    const { reached } = await checkFreeLimitReached()
    if (reached || impressionLimitReached) {
      setShowUpsell(true)
    }
    else {
      await createBulkTest()
      history.push('/')
      setShowToast(true)
      setMessage('Saved')
    }
  }

  return (
    <>
      <UpsellModal active={showUpsell} setActive={setShowUpsell} action={createWithRedirect} />
      <Tabs fitted tabs={tabs} selected={step} />
      <Page fullWidth>
        {submited && <Loading />}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ minHeight: '80vh' }}>
            {showStep(step)}
          </div>
          <div style={{ margin: '30px 20px 30px 20px' }}>
            {renderFooterButtons()}
          </div>
        </div>
      </Page>
    </>
  )
}
