import { ActionList, Button, Popover } from '@shopify/polaris'
import { MobileHorizontalDotsMajor } from '@shopify/polaris-icons'
import React, { useState } from 'react'
import useCustomHistory from '../../../../hooks/useCustomHistory'
import UpsellModal from '../../../../components/Upsell/UpsellModal'
import { useChangePlan } from '../../../Pricing/hooks'
import useContentTest from '../../../ContentTestEditor/hooks'
import { useAppBridge } from '@shopify/app-bridge-react'
import request from '../../../../lib/request'

function ContentItemMenu({ list, contentTest, deleteContentTest, changeContentTestStatus, setShowToast, setMessage }) {
  const [active, setActive] = useState(false)
  const [showUpsell, setShowUpsell] = useState(false)
  const history = useCustomHistory()
  const [changePlan] = useChangePlan()
  const { shopReachedFreePlanLimitation } = useContentTest()
  const app = useAppBridge()

  const activator = (
    <Button onClick={() => { setActive(true) }} icon={MobileHorizontalDotsMajor}>
    </Button>
  )

  const items = [
    {
      content: 'Delete', onAction: async () => {
        await deleteContentTest(contentTest._id)
        setShowToast(true)
        setMessage('Deleted')
        setActive(false)
        return
      }
    }
  ]

  if (contentTest.status === 'running') {
    items.push({
      content: 'Pause', onAction: async () => {
        await changeContentTestStatus(contentTest._id, 'paused')
        setShowToast(true)
        setMessage('Paused')
        setActive(false)
        return
      }
    })
  }

  const otherContentTestsRunning = () => {
    return list.find(item => item.testType === 'content' && item.status === 'running')
  }

  const reachedImpressionsLimit = (shop) => {
    if (shop?.pricingPlanData?.impressionLimit < 0)
      return false

    if (shop?.currentMonthImpressions < shop?.pricingPlanData?.impressionLimit )
      return false

    return true
  }

  if (contentTest.status === 'paused' && !otherContentTestsRunning()) {
    items.push({
      content: 'Resume', onAction: async () => {
        const reached_free_limitation = await shopReachedFreePlanLimitation()
        const shop = await request('default/shop', 'GET', null, undefined, app)
        const reached_impressions_limit = reachedImpressionsLimit(shop)

        if (reached_free_limitation || reached_impressions_limit)
          setShowUpsell(true)
        else
          await changeContentTestStatus(contentTest._id, 'running')
          setShowToast(true)
          setMessage('Resumed')

        setActive(false)
        return
      }
    })
  }

  if (contentTest.status !== 'completed') {
    items.push({
      content: 'Finish', onAction: async () => {
        await changeContentTestStatus(contentTest._id, 'completed')
        setShowToast(true)
        setMessage('Finished')
        setActive(false)
        return
      }
    })
  }

  const resumeWithRedirect = (redirectTo, planName) => {
    if (redirectTo === 'upgrade') {
      changePlan(planName)
    } else {
      history.push('/pricing')
    }

    setActive(false)
    return
  }

  return (
    <>
      <Popover
        active={active}
        activator={activator}
        preferredAlignment="right"
        onClose={() => setActive(false)}
      >
        <ActionList items={items} />
      </Popover>
      <UpsellModal active={showUpsell} setActive={setShowUpsell} action={resumeWithRedirect} />
    </>
  )
}

export default ContentItemMenu
