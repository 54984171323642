import { Page } from '@shopify/polaris'
import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import Welcome from './containers/WelcomePage/WelcomePage'
import Dashboard from './containers/Dashboard/Dashboard'
import Pricing from './containers/Pricing/Pricing'
import Details from './containers/Details/Details'
import BulkDetails from './containers/BulkDetails/BulkDetails'
import TestForm from './containers/TestForm/TestForm'
import BulkTestForm from './containers/BulkTestForm/BulkTestForm'
import { useGetShop } from './hooks/requests'
import { useSelector } from 'react-redux'
import ContentTestForm from './containers/ContentTestForm/ContentTestForm'
import ContentTestEditor from './containers/ContentTestEditor/ContentTestEditor'
import ContentTestDetails from './containers/ContentTestDetails/ContentTestDetails'
import HelpPage from './containers/NavigationPages/HelpPage'
import SupportPage from './containers/NavigationPages/SupportPage'
import SuggestionBoxPage from './containers/NavigationPages/SuggestionBoxPage'

function RouterComponent(props) {
  const [getShop] = useGetShop()
  const shop = useSelector(state => state.shop && state.shop.shop || null)
  const [showToast, setShowToast] = useState(false)
  const [message, setMessage] = useState('')

  useEffect(() => {
    getShop()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!shop) {
    return 'Loading...'
  }

  if (!shop.pricingPlan) {
    return <Pricing shop={shop} />
  }

  return <>
    <Switch>
      <Route exact path="/">
        <Page>
          <Dashboard shop={shop} showToast={showToast} setShowToast={setShowToast} message={message} setMessage={setMessage}/>
        </Page>
      </Route>
      <Route exact path="/welcome">
        <Page>
          <Welcome shop={shop} />
        </Page>
      </Route>
      <Route exact path="/pricing">
        <Page>
          <Pricing shop={shop} />
        </Page>
      </Route>
      <Route path="/details/:id">
        <Page>
          <Details {...props} shop={shop} />
        </Page>
      </Route>
      <Route path="/bulk_details/:id">
        <Page>
          <BulkDetails {...props} shop={shop} />
        </Page>
      </Route>
      <Route path="/help">
        {/* <Page {...props} /> */}
      </Route>
      <Route path="/test">
        <TestForm {...props} setShowToast={setShowToast} setMessage={setMessage}/>
      </Route>
      <Route path="/bulk-test">
        <BulkTestForm {...props} setShowToast={setShowToast} setMessage={setMessage}/>
      </Route>
      <Route path="/content-test/:id/editor">
        <ContentTestEditor {...props} shop={shop} setShowToast={setShowToast} setMessage={setMessage}/>
      </Route>
      <Route path="/content-test/:id/results">
        <ContentTestDetails {...props} />
      </Route>
      <Route path="/content-test-form/:id">
        <ContentTestForm {...props} />
      </Route>
      <Route path="/content-test-form">
        <ContentTestForm {...props} />
      </Route>
      <Route path="/get-help">
        <HelpPage />
      </Route>
      <Route path="/get-support">
        <SupportPage />
      </Route>
      <Route path="/suggestion-box">
        <SuggestionBoxPage />
      </Route>
    </Switch>
  </>
}

export default RouterComponent
