import { useCallback } from 'react'
import { Toast } from '@shopify/polaris'

const CustomToast = ({message, active, setActive}) => {

  console.log(message, active, setActive, 'CustomToast')

  const toggleActive = useCallback(() => setActive((active) => !active), [])

  const toastMarkup = active ? (
    <Toast content={message} onDismiss={toggleActive} />
  ) : null

  return (
    toastMarkup
  )
}

export default CustomToast
